 <template>
  <div class="article com">
    <ListTitle :category='category'></ListTitle>
    <div class="_box ">
      <div class="_title">
        {{data.title}}
      </div>
      <div class="_disc">
        时间：{{data.created_at}} 来源：{{data.from?data.from:'本网'}} 责任编辑：{{data.edit?data.edit:'admin'}}
      </div>



      <div class="_info" v-html="data.content">
      </div>
      <div v-if="data.file.length > 0" class="file">
        <span>附件下载:</span>
        <p v-for="item,i in data.file" :key="i"><a :href="item.url">{{item.name}}</a></p>
      </div>

      <div v-if="data.slide.length > 0" class="_images">
          <div v-for="item,i in data.slide" :key="i">
            <img :src="item" alt="">
          </div>
          <!-- <el-carousel indicator-position="outside" height='800px' arrow='always'>
            <el-carousel-item v-for="item in data.slide" :key="item">
                <div class="_slide" :style="{ backgroundImage: 'url(' + item + ')' }" @click="openimage(item)"></div>
            </el-carousel-item>
          </el-carousel>
          <el-dialog v-model="digimage" title="" width="50%">
            <img :src="image" alt="">
          </el-dialog> -->
        </div>
    </div>
  </div>
</template>

<script>
import ListTitle from "../components/ListTitle.vue";
export default {
  name: "articles",
  components: { ListTitle },
  created() {
    this.category_id = this.$route.query.category;
    this.id = this.$route.query.id;
    this.getData();
  },
  data() {
    return {
      category: [],
      data: [],
      digimage:false,
      image:''
    };
  },
  methods: {
    openimage(e){
      this.digimage = true;
      this.image = e
    },
    getData() {
      this.$api.get({
        url: "index/article",
        data: { id: this.id, category_id: this.category_id },
        success: (res) => {
          this.category = res.data.category;
          this.data = res.data.data;
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
